<template>
  <div>
    <DataTable
      :value="items"
      :rows="10"
      :scrollable="true"
      :rowsPerPageOptions="[10, 25, 50]"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      currentPageReportTemplate="Memuat {first} dari {last} data dari total {totalRecords}"
      class="p-datatable-sm"
    >
      <template #empty>
        <div class="text-center">Tidak ada data.</div>
      </template>
      <Column field="updated_at" header="Tgl Batch" style="min-width: 150px">
        <template #body="{ data }">
          {{ formatDate(data.updated_at) }}
        </template>
      </Column>
      <Column field="batch_no" header="No Batch" style="min-width: 170px" />
      <Column field="expired_at" header="Expired at" style="min-width: 150px">
        <template #body="{ data }">
          {{ formatDate(data.expired_at) }}
        </template>
      </Column>
      <Column field="quantity" header="QTY" style="min-width: 100px" />
      <Column
        field="nama_supplier"
        header="Supplier"
        style="min-width: 250px"
      />
      <Column v-if="showButton">
        <template #body="data">
          <Button
            type="button"
            icon="pi pi-trash"
            class="p-button-outlined p-button-danger p-button-text"
            v-tooltip.top="'Hapus batch'"
            @click="$emit('delete', data, detailId)"
          />
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import { formatDate } from '@/helpers'

export default {
  props: {
    items: {
      type: Array,
      default() {
        return []
      },
    },
    showButton: {
      type: Boolean,
      default: false,
    },
    isPurchase: {
      // isPurchase, true, ambil dari purchase order detail, false, dari sales order detail
      type: Boolean,
      default: false,
    },
    detailId: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    formatDate(value) {
      return formatDate(value)
    },
  },
}
</script>
