<template>
  <div>
    <form @submit.prevent="handleSubmit(!v$.$invalid)">
      <div class="formgrid grid">
        <div class="field col-12 md:col-6">
          <label>Tanggal</label>
          <Calendar
            v-model="form.son_at"
            dateFormat="dd/mm/yy"
            class="w-full"
            input-class="w-full"
          />
        </div>
        <div class="field col-12 md:col-6">
          <label>No</label>
          <InputText v-model="form.son_no" class="w-full" disabled autofocus />
        </div>
        <div class="field col-12">
          <label> Pabrik </label>
          <AutoComplete
            v-model="form.pabrik"
            :suggestions="acPabrik"
            :min-length="2"
            field="nama"
            class="w-full p-fluid"
            input-class="w-full"
            @complete="searchPabrik($event)"
          >
            <template #item="slotProps">
              <div class="font-medium">
                {{ slotProps.item.nama }} ({{ slotProps.item.kode }})
              </div>
              <div class="text-sm">
                Total SKU <strong>{{ slotProps.item.total_sku }}</strong>
              </div>
            </template>
            <template #chip="slotProps">
              {{ slotProps.value.kode }} - {{ slotProps.value.nama }} :
              {{ slotProps.value.total_sku }}
            </template>
          </AutoComplete>
          <small
            v-if="
              (v$.form.pabrik.$invalid && submitted) ||
              v$.form.pabrik.$pending.$response
            "
            class="p-error"
            >{{ v$.form.pabrik.required.$message }}</small
          >
        </div>
      </div>
      <div class="flex justify-content-end align-items-end">
        <Button
          :loading="loading"
          label="Simpan"
          icon="pi pi-check"
          type="submit"
        />
      </div>
    </form>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import PabrikService from '@/services/PabrikService'
import errorHandler from '@/helpers/error-handler'

export default {
  setup: () => ({ v$: useVuelidate() }),
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default() {
        return {
          son_no: null,
          son_at: new Date(),
          pabrik: {},
        }
      },
    },
  },
  created() {
    this.dataService = new PabrikService()
  },
  data() {
    return {
      submitted: false,
      acPabrik: [],
      dataService: null,
    }
  },
  computed: {
    form() {
      return this.item
    },
  },
  methods: {
    searchPabrik(e) {
      this.dataService
        .get('?filter[nama]=' + e.query)
        .then((res) => {
          this.acPabrik = res.data.data
        })
        .catch((err) => {
          errorHandler(err, 'Data Pabrik', this)
        })
    },
    handleSubmit(isFormValid) {
      this.submitted = true

      if (!isFormValid) {
        return
      }

      this.$emit('save', this.form)
    },
  },
  validations() {
    return {
      form: {
        pabrik: {},
      },
    }
  },
}
</script>
